exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-actions-tsx": () => import("./../../../src/templates/Actions.tsx" /* webpackChunkName: "component---src-templates-actions-tsx" */),
  "component---src-templates-edit-card-tsx": () => import("./../../../src/templates/EditCard.tsx" /* webpackChunkName: "component---src-templates-edit-card-tsx" */),
  "component---src-templates-help-tsx": () => import("./../../../src/templates/Help.tsx" /* webpackChunkName: "component---src-templates-help-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/Home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-new-card-tsx": () => import("./../../../src/templates/NewCard.tsx" /* webpackChunkName: "component---src-templates-new-card-tsx" */),
  "component---src-templates-new-docrates-tsx": () => import("./../../../src/templates/NewDocrates.tsx" /* webpackChunkName: "component---src-templates-new-docrates-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/Search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-use-card-tsx": () => import("./../../../src/templates/UseCard.tsx" /* webpackChunkName: "component---src-templates-use-card-tsx" */),
  "component---src-templates-view-card-tsx": () => import("./../../../src/templates/ViewCard.tsx" /* webpackChunkName: "component---src-templates-view-card-tsx" */)
}

